import { BaseLogger } from '@wix/fedops-logger';
import {
  EventPayload,
  IntegratedAppsInstallEventPayload,
  IntegrationEventPayload,
  LifecycleEvent,
} from '@wix/members-area-integration-kit';
import pricingPlans from '../../.application.json';
import { AutopilotInstallInteractions, InstallInteractions } from '../types/InstallFedops';

type EventProps = EventPayload[LifecycleEvent];
type MemebersInstallSuccessLoggerProps = {
  fedopsLogger: () => BaseLogger<string>;
  isAutopilotUser: () => Promise<boolean>;
  reportPricingPlansInstallationStage: (stage: string) => Promise<void>;
};

export const createMemebersAreaInstallLogger = ({
  fedopsLogger,
  reportPricingPlansInstallationStage,
  isAutopilotUser,
}: MemebersInstallSuccessLoggerProps) => {
  let shouldLog = false;

  const onEvent = async (event: LifecycleEvent, payload: EventPayload[LifecycleEvent]) => {
    if (shouldStartLogging(event, payload)) {
      shouldLog = true;
    }

    if (shouldLog) {
      await logEvent(event, payload);
    }

    if (shouldStopLogging(event, payload)) {
      shouldLog = false;
    }
  };

  const logEvent = async (event: LifecycleEvent, payload: EventPayload[LifecycleEvent]) => {
    let triggeredBy = '';
    if (isIntegratedAppsInstallPayload(payload)) {
      triggeredBy = payload.triggeredBy;
    }
    await reportPricingPlansInstallationStage(isTriggeredByPP(triggeredBy) ? `pp-${event}` : event);

    const isAutopilot = await isAutopilotUser();
    const Interactions = isAutopilot ? AutopilotInstallInteractions : InstallInteractions;

    switch (event) {
      case LifecycleEvent.IntegrationStart:
        fedopsLogger().interactionStarted(Interactions.Integration);
        break;
      case LifecycleEvent.MembersAreaInstallStart:
        fedopsLogger().interactionStarted(Interactions.MembersAreaInstall);
        break;
      case LifecycleEvent.IntegratedAppsInstallStart:
        fedopsLogger().interactionStarted(
          isTriggeredByPP(triggeredBy) ? Interactions.PpIntegratedAppsInstall : Interactions.IntegratedAppsInstall,
        );
        break;
      case LifecycleEvent.IntegratedAppsInstallFinish:
        fedopsLogger().interactionEnded(
          isTriggeredByPP(triggeredBy) ? Interactions.PpIntegratedAppsInstall : Interactions.IntegratedAppsInstall,
        );
        break;
      case LifecycleEvent.MembersAreaInstallFinish:
        fedopsLogger().interactionEnded(Interactions.MembersAreaInstall);
        break;
      case LifecycleEvent.IntegrationFinish:
        fedopsLogger().interactionEnded(Interactions.Integration);
        break;
      default:
    }
  };
  return onEvent;
};

const isIntegratedAppsInstallPayload = (payload: EventProps): payload is IntegratedAppsInstallEventPayload => {
  return !!payload && 'triggeredBy' in payload;
};

const isIntegrationPayload = (payload: EventProps): payload is IntegrationEventPayload => {
  return !!payload && 'firstInstall' in payload;
};

const shouldStartLogging = (event: LifecycleEvent, payload: EventPayload[LifecycleEvent]) => {
  return event === LifecycleEvent.IntegrationStart && isIntegrationPayload(payload) && payload.firstInstall;
};

const shouldStopLogging = (event: LifecycleEvent, payload: EventPayload[LifecycleEvent]) => {
  return event === LifecycleEvent.IntegrationFinish && isIntegrationPayload(payload) && payload.firstInstall;
};

const isTriggeredByPP = (triggeredBy: string) => triggeredBy === pricingPlans.appDefinitionId;
