import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export async function setPermissionsPage(editorSDK: FlowEditorSDK, token: string) {
  const { applicationId } = await editorSDK.document.tpa.app.getDataByAppDefId(token, token);
  const components = await editorSDK.document.tpa.app.getAllCompsByApplicationId(token, applicationId);
  if (components && components.length) {
    const component = components.filter((c) => c.type === 'TPA')[0];
    if (component) {
      const { pageId } = component;
      await editorSDK.document.transactions.runAndWaitForApproval(token, () =>
        editorSDK.document.siteMembers.setCustomNoPermissionsPageId(token, { pageId }),
      );
    }
  }
}
